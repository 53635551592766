import { AuthEvents, AuthProvider, HttpClientProvider } from "@my/api"
import { registerIcons } from "@my/config/src/icons"
import { config } from "@my/config/src/tamagui.config"
import { CustomToast } from "@my/ui"
import { ToastProvider, useToastController } from "@tamagui/toast"
import { useAppStore } from "app/global-state/appStore"
import { logger } from "app/telemetry/logger"
import { mixpanel } from "app/telemetry/mixpanel"
import { useCallback, useEffect } from "react"
import { useColorScheme } from "react-native"
import { TamaguiProvider, TamaguiProviderProps } from "tamagui"
import { EventBus } from "../events"
import { FeatureFlagProvider } from "../feature-flags"
import { LogoutType, useLogout } from "../hooks/useLogout"
import { ToastViewport } from "./ToastViewport"
import { useSetupQueryClient } from "app/hooks/useSetupQueryClient"
import { QueryClientProvider } from "@tanstack/react-query"

export function AppUiProvider({ children, ...rest }: Omit<TamaguiProviderProps, "config">) {
  const scheme = useColorScheme()

  useEffect(() => {
    registerIcons()
  }, [])

  return (
    <TamaguiProvider
      config={config}
      disableInjectCSS
      defaultTheme={scheme === "dark" ? "dark" : "light"}
      {...rest}
    >
      <ToastProvider swipeDirection="horizontal" duration={5000}>
        {children}

        <CustomToast />
        <ToastViewport />
      </ToastProvider>
    </TamaguiProvider>
  )
}

export function AppApiProvider({ children }: { children: React.ReactNode }) {
  const toast = useToastController()
  const { handleLogout } = useLogout()
  const { authenticationStartTime, setAuthenticationStartTime } = useAppStore()

  const onLogin = useCallback(
    (success: boolean) => {
      if (success) {
        EventBus.emit(AuthEvents.Login)
      }

      mixpanel.track("Login", {
        success,
        duration:
          authenticationStartTime ?
            new Date().getTime() - authenticationStartTime.getTime()
          : undefined,
      })
      setAuthenticationStartTime(null)
    },
    [authenticationStartTime],
  )

  const onLogout = useCallback(async () => {
    await EventBus.emitSync(AuthEvents.Logout)
  }, [])

  const onError = useCallback(
    (errorMessage: string) => toast.show("Error", { message: errorMessage }),
    [toast],
  )

  const myHandleLogout = useCallback(() => {
    handleLogout(LogoutType.BackendError)
  }, [handleLogout])

  const queryClient = useSetupQueryClient()

  return (
    <FeatureFlagProvider>
      <AuthProvider onLogin={onLogin} onLogout={onLogout} logger={logger}>
        <QueryClientProvider client={queryClient}>
          <HttpClientProvider onError={onError} logout={myHandleLogout}>
            {children}
          </HttpClientProvider>
        </QueryClientProvider>
      </AuthProvider>
    </FeatureFlagProvider>
  )
}
