import { ProgramResponse, useHttpClient } from "@my/api"
import { useModuleStore } from "../global-state/programStore"
import { useCallback } from "react"
import { writeToDebug } from "@my/config/src/debug"

/**
 * Fetches the Modules.
 */
export function useFetchModules() {
  const { setModules, setError, setFetched } = useModuleStore()
  const httpClient = useHttpClient()

  return useCallback(async () => {
    const currentIsFetched = useModuleStore.getState().isFetched
    // Ensure that modules are fetched once.
    // Because modules will not change frequently, we shouldn't need to refetch this data.
    // If a re-fetch is needed, changing isFetched to false will allow a re-fetch.
    if (currentIsFetched) {
      return
    }

    try {
      const response = await httpClient.get<ProgramResponse>("/modules", { requireAuth: false })
      writeToDebug("Modules response: ", response)
      setModules(response?.modules ?? [])
      setError(null)
      setFetched(true)
      return response?.modules
    } catch (error) {
      setError("Failed to fetch Modules")
      throw error
    }
  }, [httpClient, setModules, setError, setFetched])
}
