import { Program } from "@my/api"
import { create } from "./create"
import { unionBy } from "lodash"

type ModuleTypes = Program[keyof Program]
type ModulesDict = Record<string, Program>

interface ModuleStore {
  modules: Program[]
  modulesById: ModulesDict
  error: string | null
  isFetched: boolean
  setError: (error: string | null) => void
  setFetched: (fetched: boolean) => void
  setModules: (modules: Program[]) => void
  addModules: (newModules: Program[]) => void
  fetchModuleByProperty: (property: keyof Program, value: ModuleTypes) => Program | undefined
  getModulesByProperties: (property: keyof Program, values: ModuleTypes[]) => Program[]
  getModuleById: (id: string) => Program | undefined
}

const createModulesDict = (modules: Program[]): ModulesDict => {
  return modules.reduce<ModulesDict>((acc, module) => {
    acc[module.id] = module
    return acc
  }, {})
}

export const useModuleStore = create<ModuleStore>((set, get) => ({
  modules: [],
  modulesById: {},
  error: null,
  isFetched: false,
  setError: (error) => set({ error }),
  setFetched: (fetched) => set({ isFetched: fetched }),
  setModules: (modules) =>
    set({
      modules,
      modulesById: createModulesDict(modules),
    }),
  addModules: (newModules) =>
    set((state) => {
      const updatedModules = unionBy(newModules, state.modules, "id")
      return {
        modules: updatedModules,
        modulesById: createModulesDict(updatedModules),
      }
    }),

  // Fetch a module by a specific property
  fetchModuleByProperty: (property, value) => {
    const { modules } = get()
    return modules.find((module: Program) => module[property] === value)
  },

  // Get a list of modules by a list of specific property values
  getModulesByProperties: (property, values) => {
    const { modules } = get()
    return modules.filter((module: Program) => values.includes(module[property]))
  },

  getModuleById: (id) => {
    const { modulesById } = get()
    return modulesById[id]
  },
}))
